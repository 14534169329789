import * as React from 'react';

import { OneColumn } from '@components/Layouts/OneColumn';
import Title from '@components/Title';

import { useTranslation } from '@external/react-i18next';

const DataSelector = () => {
  const { t } = useTranslation();

  return (
    <OneColumn className="mb-20">
      <Title>{t('data-selector.title', 'Verify & Continue')}</Title>
      <div>
        {t(
          'data-selector.description',
          'You have access to multiple districts, clubs or roles. Please select the appropriate option to continue. '
        )}
      </div>
    </OneColumn>
  );
};

export default DataSelector;
