import React from 'react';

import DataSelector from '@presenters/web/pages/DataSelector';

import RequireLogin from '../components/Auth/RequireLogin';

const ReportsDataSelector: React.FC = () => {
  return (
    <RequireLogin>
      <DataSelector />
    </RequireLogin>
  );
};

export default ReportsDataSelector;
